<template>
  <div class="container wrapper wrapper_space-top">
    <div class="d-flex justify-space-between">
      <h1 class="pageHeader">
        {{ isReviewing ? 'Write Review' : 'Job Organizer' }}
      </h1>
      <div v-if="$isMobile()">
        <v-icon
          dark
          @click="$openModal('jobFilter')"
        >
          mdi-tune
        </v-icon>
      </div>
    </div>
    <JobOrganizerListing @isReviewing="isReviewing = $event"/>
</div>
</template>

<script>
  import JobOrganizerListing from '@/views/JobOrganizers/JobListing.vue';
  export default {
    name: 'JobOrganizer',
    data() {
      return {
        isReviewing: false
      }
    },
    components: {
      JobOrganizerListing
    }
  };
</script>
