<template>
  <div>
    <div v-if="reviewList.length">
      <h3 class="mb-8">({{ reviewList.length || 0 }})</h3>
      <div class="w-100 mb-12">
        <v-row
          v-for="user in reviewList"
          :key="user.id"
          no-gutters
          class="userContainer mb-4"
          align="center"
          justify="space-between"
        >
          <v-col cols="6" md="2" class="d-flex justify-center align-center">
            <v-avatar size="100"> <img :src="getImage(user)" crossorigin="anonymous"/></v-avatar
          ></v-col>
          <v-col>
            <span>{{ user.first_name }}</span>
            <br />
            <span>{{ user.display_name }}</span>
          </v-col>
          <v-col md="3" class="text-center">
            <v-btn class="btn1 mt-3 mt-md-0" @click="openReviewModal(user)"> Select </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else class="grey--text">
      {{ $t('message.noRecords') }}

    </div>
    <div class="d-flex justify-center my-12">
      <div class="text-center">
        <div class="underlineBtn mt-7" @click="$emit('back')">Back</div>
      </div>
    </div>
    <AppModalBase id="reviewModal" width="900" @closeModal="selectedReviewee = null;">
      <ReviewModalContent
        :reviewJobId="reviewJobId"
        :selectedReviewee="selectedReviewee"
        @reviewSuccess="reviewSuccess"
      />
    </AppModalBase>
  </div>
</template>

<script>
  import ReviewModalContent from '@/components/job/ReviewModalContent.vue';

  export default {
    name: 'TalentList',
    components: {
      ReviewModalContent
    },
    props: {
      reviewList: {
        type: Array,
        default: () => ([])
      },
      reviewJobId: {
        type: Number,
        required: true
      }
    },
    data: () => ({
      selectedReviewee: null
    }),
    computed: {},
    methods: {
      reviewSuccess() {
        this.$closeModal();
        this.selectedReviewee = null;
        this.openAppDialogInfo(
          'success',
          'Review submitted',
          '',
          [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
                this.$emit('loadList', this.reviewJobId);
              }
            }
          ]
        );
      },
      openReviewModal(u) {
        this.selectedReviewee = u.id;
        this.$openModal('reviewModal');
      },
      getImage(user) {
        return (
          user?.headshot_file?.media_path ||
          user?.company_logo?.media_path ||
          this?.$store?.getters['constants/avatarImageUrl']
        );
      }
    },
    
  };
</script>
<style lang="scss">
.userContainer {
  background-color: #242424;
  border-radius: 5px;
  padding: 1rem;
}
</style>
