<template>
  <div>
    <div v-for="item in items" :key="item.job_id">
      <div class="mb-5">
        <div class="headerBar d-flex justify-space-between align-center px-10">
          <span class="aqua-blue--text font-weight-medium"
            >Status: {{ item.job_status }}</span
          >
          <div>
            <v-icon
              dark
              size="30"
              @click="
                $router.push({
                  path: '/application_management'
                })
              "
            >
              mdi-clipboard-text-outline
            </v-icon>
          </div>
        </div>
        <div class="listBg w-100 px-10 py-5 rounded-t-0">
          <h3 class="white--text">{{ item.title }}</h3>
          <p class="deep-blue--text font-weight-medium mt-2">
            {{ getProjectType(item.project_type_id) }}
          </p>
          <h5 class="white--text">Synopsis</h5>

          <p class="white--text">
            {{ item.synopsis }}
          </p>
          <h5 class="white--text">Job Location</h5>
          <div class="d-flex">
            <template v-for="i in getCountries(item.applicant_country_id)">
              <div
                class="white--text d-flex align-center mt-3 mr-4"
                :key="i.id"
              >
                <v-avatar class="mr-3" :size="$isMobile() ? 20 : 40">
                  <img
                    :src="i.image_url_circle"
                    crossorigin="anonymous"
                  />
                </v-avatar>
                {{ i.name }}
              </div>
            </template>
          </div>

          <hr class="divider my-5" />
          <v-row no-gutters>
            <v-col cols="6" sm="3">
              <h5 class="white--text">Production Details</h5>
              Start
              <br />
              {{ item.production_period_from | defaultDate }}
            </v-col>
            <v-col cols="6" sm="3" align-self="end">
              End
              <br />
              {{ item.production_period_to | defaultDate }}</v-col
            >
            <v-col cols="12" sm="3" :class="$isMobile() ? 'mt-3' : ''">
              <div v-if="item.shooting_days" class="d-flex align-center mb-2">
                <div>
                  <v-icon dark>
                    mdi-calendar-blank-outline
                  </v-icon>
                  <!-- <v-img
                    src="/assets/image/icons/calendar.png"
                    contain
                    class="mr-2"
                    width="30"
                    height="30"
                  /> -->
                </div>
                <h5 class="white--text ml-2">
                  {{ item.shooting_days }} Shoot days
                </h5>
              </div>
              <div v-if="item.hours_per_day" class="d-flex align-center">
                <div>
                  <v-icon dark>
                    mdi-calendar-clock-outline
                  </v-icon>
                  <!-- <v-img
                    src="/assets/image/icons/shootDuration.png"
                    contain
                    class="mr-2"
                    width="30"
                    height="30"
                  /> -->
                </div>
                <h5 class="white--text ml-2">
                  {{ item.hours_per_day }} Hours per day
                </h5>
              </div>
            </v-col>
            <v-col cols="12" sm="3" class="d-flex">
              <v-btn class="btn1 ml-auto" @click="$openChat()">
                {{ $t('label.chat') }}
              </v-btn>
            </v-col>
          </v-row>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { uiHelper } from '@/utils';
  import { LOCAL } from '@/constants';

  export default {
    name: 'PendingListItem',
    props: ['items'],
    data: () => ({}),
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      projectTypes() {
        let list = [];
        this.constantsData?.project_types?.forEach((x) => {
          list.push({
            id: x.id,
            name: x.name
          });
        });

        return list;
      }
    },
    methods: {
      getCountries(ids = []) {
        return (this?.$store?.getters['constants/allCountries'] || '').filter(
          (i) => ids.find((i2) => i2 == i.id)
        );
      },
      getProjectType(id) {
        const projectType = this.projectTypes.find((x) => x.id === id);
        return projectType.name;
      }
    }
  };
</script>
<style lang="scss" scoped>
  .headerBar {
    background-color: #454545;
    height: 50px;
  }
</style>
