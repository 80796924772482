<template>
  <div>
    <template v-for="item in items">
      <v-row
        class="listBg mx-0 px-10 py-5 rounded-lg mb-5"
        justify="space-between"
        :key="item.casting_job_draft_id"
      >
        <div>
          <h3 class="deep-blue--text font-weight-medium">
            {{ getProjectType(item.project_type_id) }}
          </h3>
          <h3 class="white--text">{{ item.title }}</h3>
        </div>

        <v-icon
          v-if="$isMobile()"
          dark
          class="ml-10 mr-5"
          @click="deletePosting"
          >mdi-delete</v-icon
        >
        <div
          :class="
            'd-flex ' +
            ($isMobile() ? 'justify-center w-100 mt-5' : 'align-center')
          "
        >
          <v-btn
            class="btn1 md white--text"
            @click="
              $router.push({
                name: 'create_job',
                query: {
                  draft_id: item.casting_job_draft_id
                }
              })
            "
          >
            Continue
          </v-btn>
          <!-- <v-img src="/assets/image/icons/dots.png" class="ml-10 mr-5" width="50" /> -->
          <v-icon
            dark
            v-if="!$isMobile()"
            class="ml-10 mr-5"
            @click="
              removeId = item.casting_job_draft_id;
              $openModal('deleteConfirmationModal');
            "
            >mdi-delete</v-icon
          >
        </div>
      </v-row>
    </template>
    <ModalBase
      width="500"
      id="deleteConfirmationModal"
      @closeModal="removeId = null"
    >
      <div>
        <div class="text-center text-uppercase my-5 font-weight-bold lg-font">
          Confirm to Remove This Post?
        </div>
        <div class="text-center pt-8">
          <v-btn class="btn1 mb-4" @click="removeDraftJob"> Remove </v-btn>
          <div
            class="underlineBtn"
            @click="
              removeId = null;
              $closeModal();
            "
          >
            Cancel
          </div>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
  import { uiHelper } from '@/utils';
  import { LOCAL } from '@/constants';
  import ModalBase from '@/components/base/ModalBase';
  import { JOB_ORGANIZER } from '@/api';

  export default {
    name: 'DraftListItem',
    props: ['items'],
    components: {
      ModalBase
    },
    data() {
      return {
        removeId: null
      };
    },
    computed: {
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      projectTypes() {
        let list = [];
        this.constantsData?.project_types?.forEach((x) => {
          list.push({
            id: x.id,
            name: x.name
          });
        });

        return list;
      }
    },
    methods: {
      async removeDraftJob() {
        this.$startLoading();
        try {
          await JOB_ORGANIZER.removeDraftJob(this.removeId);
          this.$emit('loadJobData');
          this.$closeModal();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
        this.removeId = null;
      },
      getProjectType(id) {
        const projectType = this.projectTypes.find((x) => x.id === id);
        return projectType.name;
      }
    }
  };
</script>
<style lang="scss">

</style>
