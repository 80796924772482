<template>
  <div>
    <template v-for="item in items">
      <div class="mb-5" :key="item.job_id">
        <div class="headerBar d-flex justify-space-between align-center px-10">
          <span class="pink2--text font-weight-medium"
            >Status: {{ item.job_status }}</span
          >
          <div>
            <v-icon
              dark
              size="30"
              @click="
                isTalentRole
                  ? $router.push({
                      path: '/application_management'
                    })
                  : $router.push({
                      path: '/create_job/invitation?',
                      query: { job_id: item.job_id }
                    })
              "
            >
              {{ isTalentRole ? 'mdi-clipboard-text-outline' : 'mdi-account-plus-outline' }}
            </v-icon>
          </div>
        </div>
        <div class="listBg w-100 px-10 py-5 rounded-t-0">
          <div class="d-flex">
            <h3 class="white--text">{{ item.title }}</h3>
            <v-menu
              dark
              left
              z-index="1"
              offset-y
              v-if="item.producer_uid == user.id && !isTalentRole"
            >
              <template v-slot:activator="{ on }">
                <v-icon dark v-on="on" class="ml-auto"
                  >mdi-dots-horizontal</v-icon
                >
              </template>
              <v-list>
                <v-list-item
                  @click="
                    $router.push({
                      name: 'create_job',
                      query: {
                        job_id: item.job_id
                      }
                    })
                  "
                >
                  <v-list-item-title class="px-8">
                    Edit Post
                  </v-list-item-title>
                </v-list-item>
                <hr class="divider" />
                <v-list-item
                  @click="
                    $openModal('deleteConfirmationModal');
                    removeId = item.job_id;
                  "
                >
                  <v-list-item-title class="px-8"
                    >Remove Post</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <p class="deep-blue--text font-weight-medium mt-4">
            {{ getProjectType(item.project_type_id) }}
          </p>
          <h5 class="white--text">Synopsis</h5>

          <p class="white--text">
            {{ item.synopsis }}
          </p>
          <h5 class="white--text">Job Location</h5>
          <div class="d-flex">
            <template v-for="i in getCountries(item.applicant_country_id)">
              <div
                class="white--text d-flex align-center mt-3 mr-4"
                :key="i.id"
              >
                <v-avatar class="mr-3" :size="$isMobile() ? 20 : 40">
                  <img crossorigin="anonymous" :src="i.image_url_circle"/>
                </v-avatar>
                {{ i.name }}
              </div>
            </template>
          </div>
          <div
            class="mt-9"
            v-if="item.applications && item.applications.length && checkRoles(item.applications)"
          >
            <h5 class="pink2--text" style="text-decoration: underline">
              <a
                @click="
                  showList = item.applications;
                  $openModal('listOfRoleModal');
                "
              >
                List of Role
              </a>
            </h5>
          </div>

          <hr class="divider my-5" />
          <v-row no-gutters>
            <v-col cols="6" sm="3">
              <h5 class="white--text">Production Details</h5>
              Start
              <br />
              {{ item.production_period_from | defaultDate }}
            </v-col>
            <v-col cols="6" sm="3" align-self="end">
              End
              <br />
              {{ item.production_period_to | defaultDate }}</v-col
            >
            <v-col cols="12" sm="3" :class="$isMobile() ? 'mt-3' : ''">
              <div v-if="item.shooting_days" class="d-flex align-center mb-2">
                <div>
                  <v-icon dark>
                    mdi-calendar-blank-outline
                  </v-icon>
                  <!-- <v-img
                    src="/assets/image/icons/calendar.png"
                    contain
                    class="mr-2"
                    width="30"
                    height="30"
                  /> -->
                </div>
                <h5 class="white--text ml-2">
                  {{ item.shooting_days }} Shoot days
                </h5>
              </div>
              <div v-if="item.hours_per_day" class="d-flex align-center">
                <div>
                  <v-icon dark>
                    mdi-calendar-clock-outline
                  </v-icon>
                  <!-- <v-img
                    src="/assets/image/icons/shootDuration.png"
                    contain
                    class="mr-2"
                    width="30"
                    height="30"
                  /> -->
                </div>
                <h5 class="white--text ml-2">
                  {{ item.hours_per_day }} Hours per day
                </h5>
              </div>
            </v-col>
            <v-col cols="12" sm="3" align="center">
              <v-btn
                v-show="item.to_review"
                class="btn1 white--text"
                :class="$isMobile() ? 'md mt-5' : ''"
                @click="$emit('review', item.job_id)"
              >
                Write Review
              </v-btn>
            </v-col>
          </v-row>
          <div></div>
        </div>
      </div>
    </template>
    <ModalBase id="listOfRoleModal" @closeModal="showList = []">
      <v-row class="mt-3">
        <v-col cols="12">
          <v-row
            no-gutters
            justify="space-between"
            class="text-uppercase font-weight-bold"
          >
            <v-col cols="4"> Role Info </v-col>
            <v-col cols="4" class="text-right"> Status </v-col>
          </v-row>
        </v-col>
        <v-col v-for="role in showList" :key="role.casting_application_id" cols="12">
          <v-row no-gutters justify="space-between" v-if="role.casting_role_id">
            <v-col cols="4">
              <div class="pink2--text" style="font-size: 15px">
                {{ role.character }}
              </div>
              <div>
                {{ role.display_name }}
              </div>
            </v-col>
            <v-col cols="4" class="text-right align-self-center">
              {{ role.status }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </ModalBase>
    <ModalBase
      width="500"
      id="deleteConfirmationModal"
      @closeModal="removeId = null"
    >
      <div>
        <div class="text-center text-uppercase my-5 font-weight-bold lg-font">
          Confirm to Remove This Post?
        </div>
        <div class="text-center pt-8">
          <v-btn class="btn1 mb-4" @click="removeJob"> Remove </v-btn>
          <div
            class="underlineBtn"
            @click="
              $closeModal();
              removeId = null;
            "
          >
            No
          </div>
        </div>
      </div>
    </ModalBase>
  </div>
</template>

<script>
  // import JobOrganizerReview from '@/views/JobOrganizers/Review.vue';
  // import TalentList from '@/views/JobOrganizers/TalentList.vue';
  import { uiHelper } from '@/utils';
  import { LOCAL } from '@/constants';
  import ModalBase from '@/components/base/ModalBase';
  import { JOB_ORGANIZER } from '@/api';

  export default {
    name: 'OnGoingListItem',
    props: ['items', 'user'],
    components: {
      ModalBase,
      // JobOrganizerReview,
      // TalentList
    },
    data: () => ({
      showList: [],
      removeId: null,
    }),
    computed: {
      isTalentRole() {
        return this.user?.role === 'talent' || this.user?.role === 'agency';
      },
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      projectTypes() {
        let list = [];
        this.constantsData?.project_types?.forEach((x) => {
          list.push({
            id: x.id,
            name: x.name
          });
        });

        return list;
      }
    },
    methods: {
      getCountries(ids = []) {
        return (this?.$store?.getters['constants/allCountries'] || '').filter(
          (i) => ids.find((i2) => i2 == i.id)
        );
      },
      getProjectType(id) {
        const projectType = this.projectTypes.find((x) => x.id === id);
        return projectType.name;
      },
      checkRoles(applications) {
        return applications.some((x) => x.character && x.casting_role_id);
      },
      async removeJob() {
        this.$startLoading();
        try {
          await JOB_ORGANIZER.removeJob(this.removeId);
          this.$emit('loadJobData');
          this.$closeModal();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
        this.removeId = null;
      },
    }
  };
</script>
<style lang="scss" scoped>
  .headerBar {
    background-color: #454545;
    height: 50px;
  }
  .flag {
    transform: scale(1.5, 2);
  }
</style>
