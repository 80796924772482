<template>
  <div class="mt-10 w-100">
    <div v-if="!reviewJobId" class="d-flex w-100 justify-space-between">
      <div>
        <v-btn
          v-if="!isTalentRole"
          :class="(currentTab == 'draft' ? 'active ' : '') + 'tabBtn'"
          @click="currentTab = 'draft'"
        >
          Draft
        </v-btn>
        <v-btn
          v-else
          :class="(currentTab == 'pending' ? 'active ' : '') + 'tabBtn'"
          @click="currentTab = 'pending'"
        >
          Pending
        </v-btn>
        <v-btn
          :class="(currentTab == 'on-going' ? 'active ' : '') + 'ml-5 tabBtn'"
          @click="currentTab = 'on-going'"
        >
          On-going
        </v-btn>
        <v-btn
          :class="(currentTab == 'past' ? 'active ' : '') + 'ml-5  tabBtn'"
          @click="currentTab = 'past'"
        >
          Past
        </v-btn>
      </div>
      <div v-if="!$isMobile()">
        <v-icon
          dark
          @click="$openModal('jobFilter')"
        >
          mdi-tune
        </v-icon>
      </div>
    </div>
    <div class="jobListing mt-10">
      <template v-if="!reviewJobId">
        <DraftListItem v-if="currentTab == 'draft'" :items="draft" @loadJobData="loadData"/>
        <PendingListItem v-else-if="currentTab == 'pending'" :items="pending" />
        <OnGoingListItem
          v-else-if="currentTab == 'on-going'"
          :items="ongoing"
          :user="me"
          @review="loadReviewList"
          @loadJobData="loadData"
        />
        <PastListItem
          v-else
          :items="past"
          :user="me"
          @review="loadReviewList"
        />
      </template>
      <template v-else>
        <ReviewList
          :reviewList="reviewList"
          :reviewJobId="reviewJobId"
          @loadList="loadReviewList"
          @back="
            $emit('isReviewing', false); 
            loadData();
            reviewJobId = null; 
            reviewList = [];
          "
        />
      </template>
    </div>
    <ModalBase id="jobFilter" width="900"
      ><JobFilter @apply="updateQuery"
    /></ModalBase>
  </div>
</template>

<script>
  import DraftListItem from '@/views/JobOrganizers/DraftListItem.vue';
  import PendingListItem from '@/views/JobOrganizers/PendingListItem.vue';
  import OnGoingListItem from '@/views/JobOrganizers/OngoingListItem.vue';
  import PastListItem from '@/views/JobOrganizers/PastListItem.vue';
  import JobFilter from '@/views/JobOrganizers/JobFilter.vue';
  import ReviewList from '@/components/job/ReviewList.vue';
  import ModalBase from '@/components/base/ModalBase';
  import { mapGetters } from 'vuex';
  import { JOB_ORGANIZER } from '@/api';

  export default {
    name: 'JobOrganizerListing',

    components: {
      DraftListItem,
      PendingListItem,
      OnGoingListItem,
      PastListItem,
      JobFilter,
      ReviewList,
      ModalBase
    },
    props: {

    },
    data: () => ({
      currentTab: 'on-going',
      ongoing: [],
      past: [],
      pending: [],
      draft: [],
      reviewJobId: null,
      reviewList: []
    }),
    computed: {
      ...mapGetters({
        me: ['auth/me']
      }),

      isTalentRole() {
        return this?.me?.role === 'talent' || this?.me?.role === 'agency';
      }
    },
    methods: {
      async loadData(query) {
        this.$startLoading();
        try {
          const data = await JOB_ORGANIZER.getJobList(query);
          if (this.isTalentRole) {
            this.ongoing = [...(data?.data?.ongoing || [])] || [];
          } else {
            const draftJob = await JOB_ORGANIZER.getDraftJob(query);
            this.ongoing =
              [
                ...(data?.data?.ongoing || []),
                ...(data?.data?.pending || [])
              ] || [];
            this.draft = [...draftJob.data.items] || [];
          }

          this.past = data?.data?.past || [];
          this.pending = data?.data?.pending || [];
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      updateQuery(query) {
        this.loadData(query);
      },
      async loadReviewList(id) {
        this.$startLoading();
        try {
          const data = await JOB_ORGANIZER.getCastingJobToReview(id);
          this.reviewList = data?.data || [];
          this.$scrollToTop();
          this.reviewJobId = id;
          this.$emit('isReviewing', true);
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
    },
    async mounted() {
      await this.loadData();
      const reviewId = this.$route.params?.reviewId || null;
      const listType = this.$route.params?.type || null;
      if(reviewId) {
        const inOngoingList = this.ongoing?.map((x) => x.job_id).includes(reviewId);
        this.currentTab = inOngoingList ? 'on-going' : 'past';
        this.loadReviewList(reviewId);
      }
      if(listType) {
        this.currentTab = listType;
      }
    }
  };
</script>
<style lang="scss">

</style>
