<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-4">
      <div class="white--text text-uppercase"><h2>{{ $t('label.writeReview') }}</h2></div>
      <div
        v-if="!$isMobile()"
        class="white--text cursor-pointer"
        @click="comment = null; rating = 0;"
      >
        <h2>CLEAR</h2>
      </div>
    </div>
    <div class="mb-5 d-inline-block">
      <h3 class="mt-5 mb-4">RATING</h3>
      <CustomRating v-model="rating" />
    </div>
    <div>
      <h3 class="my-2">REVIEW</h3>
      <v-textarea
        solo
        name="comment"
        label=""
        v-model="comment"
        counter="500"
      ></v-textarea>
    </div>

    <div class="text-center mt-10">
      <v-btn
        class="btn1 white--text mt-10"
        :class="$isMobile() ? 'md mt-5' : ''"
        @click="submitReview"
      >
        Submit
      </v-btn>
    </div>
  </div>
</template>

<script>
  import CustomRating from '@/components/inputs/CustomRating.vue';
  import { JOB_ORGANIZER } from '@/api';

  export default {
    name: 'JobOrganizerReview',
    components: { CustomRating },
    props: {
      reviewJobId: {
        type: Number,
        required: true
      },
      selectedReviewee: {
        type: Number
      }
    },
    data: () => ({
      comment: null,
      rating: 0
    }),
    methods: {
      async submitReview() {
        this.$startLoading();
        try {
          await JOB_ORGANIZER.postJobReview(this.reviewJobId, {
            reviewee_uid: this.selectedReviewee,
            comment: this.comment,
            rating: this.rating
          });
          this.$emit('reviewSuccess');
        } catch (e) {
          console.error(e);
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Submit Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
    }
  };
</script>
<style lang="scss">
</style>
