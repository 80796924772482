<template>
  <div class="custom-date-picker" @blur="open = false">
    <v-menu
      v-model="open"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="selected"
          :class="{ open: open }"
          @click="open = !open"
          v-bind="attrs"
          v-on="on"
        >
          <span v-if="value">{{ value | defaultDate }}</span>
          <div v-else class="d-flex justify-space-between align-center">
            Select a Date <span class="select"></span>
          </div>
        </div>
      </template>
      <v-date-picker v-model="view_model" @input="open = false"></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
  export default {
    name: 'CustomDatePicker',
    props: {
      value: {
        type: String,
        required: false,
        default: ''
      },
      placeholder: {
        type: String,
        required: false,
        default: null
      },
      tabindex: {
        type: Number,
        required: false,
        default: 0
      }
    },
    computed: {
      view_model: {
        get() {
          console.log(this.value);
          return this.value;
        },
        set(value) {
          return this.$emit('input', value);
        }
      }
    },
    data() {
      return {
        open: false
      };
    }
  };
</script>
<style scoped>
  .custom-select {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;
    height: 47px;
    line-height: 47px;
  }

  .custom-select .selected {
    background-color: transparent;
    /* border-radius: 6px; */
    /* border: 1px solid #666666; */
    color: #fff;
    /* padding-left: 1em; */
    cursor: pointer;
    user-select: none;
  }

  .custom-select .selected.open {
    /* border: 1px solid #ad8225;
    border-radius: 6px 6px 0px 0px; */
  }

  .select {
    width: 0;
    height: 0;
    border: solid white;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }

  .custom-select .items {
    color: #fff;
    border-radius: 0px 0px 6px 6px;
    overflow: hidden;
    /* border-right: 1px solid #ad8225;
    border-left: 1px solid #ad8225;
    border-bottom: 1px solid #ad8225; */
    position: absolute;
    background-color: #0a0a0a;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .custom-select .items div {
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
  }

  .custom-select .items div:hover {
    background-color: #ad8225;
  }

  .selectHide {
    display: none;
  }
</style>
