<template>
  <div class="mt-5">
    <div class="d-flex justify-space-between align-center">
      <div class="white--text"><h2>FILTER</h2></div>
      <div class="white--text cursor-pointer" @click="reset">
        <h2>CLEAR</h2>
      </div>
    </div>
    <v-row class="mt-6" justify-md="space-between">
      <v-col cols="12" sm="4">
        <h2 class="white--text">SORTING</h2>
        <br />

        <CustomSelect :options="sortFilter" v-model="filter.sorting" />
      </v-col>
      <v-col cols="12" sm="7">
        <h2 class="white--text">DATE</h2>

        <br />
        <v-row class="mt-0" justify="space-between">
          <v-col cols="12" sm="5">
            <CustomDatePicker v-model="filter.date_from" />
          </v-col>
          <v-col cols="12" sm="1"> - </v-col>
          <v-col cols="12" sm="5">
            <CustomDatePicker v-model="filter.date_to" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="text-center mt-10">
      <v-btn class="btn1 white--text mt-10" @click="$emit('apply', filter)">
        Apply
      </v-btn>
    </div>
  </div>
</template>

<script>
  import CustomSelect from '@/components/inputs/CustomSelect.vue';
  import CustomDatePicker from '@/components/inputs/CustomDatePicker.vue';

  export default {
    name: 'JobFilter',
    components: { CustomSelect, CustomDatePicker },
    computed: {
      sortFilter() {
        return this?.$store?.getters['constants/jobOrganizerSortFilter'];
      }
    },
    methods: {
      reset() {
        this.filter = {
          sorting: null,
          date_from: '',
          date_to: ''
        };
      }
    },
    data: () => ({
      options: [{ title: 'Oldest to newest', value: '' }],
      filter: {
        sorting: null,
        date_from: '',
        date_to: ''
      }
    })
  };
</script>
<style lang="scss">
</style>
