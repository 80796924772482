import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.items),function(item){return [_c(VRow,{key:item.casting_job_draft_id,staticClass:"listBg mx-0 px-10 py-5 rounded-lg mb-5",attrs:{"justify":"space-between"}},[_c('div',[_c('h3',{staticClass:"deep-blue--text font-weight-medium"},[_vm._v(" "+_vm._s(_vm.getProjectType(item.project_type_id))+" ")]),_c('h3',{staticClass:"white--text"},[_vm._v(_vm._s(item.title))])]),(_vm.$isMobile())?_c(VIcon,{staticClass:"ml-10 mr-5",attrs:{"dark":""},on:{"click":_vm.deletePosting}},[_vm._v("mdi-delete")]):_vm._e(),_c('div',{class:'d-flex ' +
          (_vm.$isMobile() ? 'justify-center w-100 mt-5' : 'align-center')},[_c(VBtn,{staticClass:"btn1 md white--text",on:{"click":function($event){return _vm.$router.push({
              name: 'create_job',
              query: {
                draft_id: item.casting_job_draft_id
              }
            })}}},[_vm._v(" Continue ")]),(!_vm.$isMobile())?_c(VIcon,{staticClass:"ml-10 mr-5",attrs:{"dark":""},on:{"click":function($event){_vm.removeId = item.casting_job_draft_id;
            _vm.$openModal('deleteConfirmationModal');}}},[_vm._v("mdi-delete")]):_vm._e()],1)],1)]}),_c('ModalBase',{attrs:{"width":"500","id":"deleteConfirmationModal"},on:{"closeModal":function($event){_vm.removeId = null}}},[_c('div',[_c('div',{staticClass:"text-center text-uppercase my-5 font-weight-bold lg-font"},[_vm._v(" Confirm to Remove This Post? ")]),_c('div',{staticClass:"text-center pt-8"},[_c(VBtn,{staticClass:"btn1 mb-4",on:{"click":_vm.removeDraftJob}},[_vm._v(" Remove ")]),_c('div',{staticClass:"underlineBtn",on:{"click":function($event){_vm.removeId = null;
            _vm.$closeModal();}}},[_vm._v(" Cancel ")])],1)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }