<template>
  <v-rating
    v-model="view_model"
    hover
    icon-label="custom icon label text {0} of {1}"
  >
    <template v-slot:item="props">
      <v-img
        v-if="props.isFilled"
        src="/assets/image/icons/ratingStarActive.png"
        class="rating-star"
        width="35"
        @click="view_model = (props.index == 0 && view_model == 1) ? 0 : (props.index + 1)"
      ></v-img>
      <v-img
        v-else
        class="rating-star"
        src="/assets/image/icons/ratingStar.png"
        width="35"
        @click="props.click"
      ></v-img> </template
  ></v-rating>
</template>
<script>
  export default {
    name: 'CustomRating',
    props: {
      value: {
        type: [String, Number]
      }
    },
    computed: {
      view_model: {
        get() {
          // console.log(this.name, this.value)
          return this.value;
        },
        set(newVal) {
          // console.log(this.name, newVal)
          this.$emit('input', newVal);
        }
      }
    }
  };
</script>
<style scoped>
  .rating-star {
    float: left;
  }
</style>
